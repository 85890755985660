export const BAR_COLORS = ["#88c92d", "#9bd0fe", "#6beac7", "#62cff7", "#c7f05f"];

export const WORD_CLOUD_COLORS = ["#f57", "#fd7", "#424", "#4cc", "#4af"];

export const CLOUD_TYPE = {
  diagonalCloud: "diagonal_cloud",
  crossCloud: "cross_cloud",
  snowflakeCloud: "snowflake_cloud",
  randomCloud: "random_cloud",
  horizontalCloud: "horizontal_cloud",
};

export const CLOUD_FOR_TESTS = [
  ["remejet", 12],
  ["ozdijvo", 4],
  ["vevkazuk", 100],
  ["nittelosu", 4],
  ["gec", 4],
  ["lesankud", 4],
  ["dij", 4],
  ["ik", 4],
  ["rasov", 4],
  ["nisina", 4],
  ["vaiboli", 4],
  ["ebekidpeg", 2],
  ["zomlinnuj", 2],
  ["lifiwa", 2],
  ["rarapa", 2],
  ["ninab", 20],
  ["colgana", 2],
  ["pere", 2],
  ["lihvujve", 2],
  ["inu", 2],
  ["ohowgo", 2],
  ["cupjakuse", 2],
  ["lajve", 2],
  ["cagamre", 1],
  ["fif", 1],
  ["esokagfi", 1],
  ["zubabmu", 1],
  ["cene", 1],
  ["ja", 1],
  ["nitza", 1],
  ["tomejop", 1],
  ["ijsuali", 1],
  ["hommeos", 1],
  ["hicvu", 1],
  ["ha", 1],
  ["ilure", 1],
  ["az", 1],
  ["lo ", 1],
  ["ottiki", 1],
  ["redtuji", 1],
  ["mi", 1],
  ["ihuojore", 1],
  ["nibahij", 1],
  ["duvracku", 1],
  ["pus", 1],
  ["negbaati", 1],
  ["cipkova", 1],
  ["hurbekuto", 1],
];

export const BAR_CHART_VIEW_TYPE = {
  horizontal: "horizontal",
  vertical: "vertical",
};
