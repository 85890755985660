<template>
  <div
    class="bar-chart"
    :class="{
      'chart-voting': isVoting,
      'vertical-set': position === 'vertical',
      dark: isDark,
    }"
  >
    <div class="title">
      <!-- TODO: Правка во время релиза  -->
      <!-- <b>{{ $t("SpeakerQuestions.answerModal.question") }}</b> -->
      {{ chart.name }}
    </div>

    <div v-for="group in chart.questions_group" class="gradient-groups">
      <div class="title">{{ group.title }}</div>
      <transition-group v-if="position === 'vertical'" tag="div" class="bars-set" name="flip-bars">
        <vertical-bar
          v-for="(bar, index) in group.answers"
          :key="bar.variantId"
          :bar-data="bar"
          :index="index"
          :dark="isDark"
          :def-width="calculatedVerticalBarWidth"
          :color="giveColor(index)"
          :is-leader="index === summaryParameters.maxIndex"
        />
      </transition-group>
      <transition-group v-else tag="div" class="bars-set horizontal" name="flip-bars">
        <horizontal-bar
          v-for="(bar, index) in group.answers"
          :key="bar.variantId"
          :class="{
            'bar--increased-mb': answers.length === 2 && index === 0 && isScreen,
          }"
          :bar-data="bar"
          :index="index"
          :dark="isDark"
          :color="giveColor(index)"
          :chart="chart"
          :is-leader="index === summaryParameters.maxIndex"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BAR_CHART_VIEW_TYPE, BAR_COLORS } from "@/constants/room-tests/room-tests-const";
import { DARK_THEME } from "@/constants/themes/themes-const";
import { landing, room } from "@/store/modules/store.namespaces";
import userCounterMixin from "@/components/common/diagrams/mixins/userCounterMixin";
import VerticalBar from "./components/VerticalBar";
import HorizontalBar from "./components/HorizontalBar";

export default {
  name: "GradientBarChart",
  components: {
    HorizontalBar,
    VerticalBar,
  },
  mixins: [userCounterMixin],
  props: {
    chart: {
      type: Object,
      default: () => {},
    },
    isVoting: {
      type: Boolean,
      default: false,
    },
    useColors: {
      type: Boolean,
      default: false,
    },
    barIndent: {
      type: Number,
      default: 20,
    },
    minBarWidth: {
      type: Number,
      default: 85,
    },
    sideBarDelay: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      // Узкий ли градиент
      isNarrowBar: false,
    };
  },
  computed: {
    ...mapState(["interactiveTheme"]),
    ...mapState(landing, {
      landingTemplate: "template",
    }),
    ...mapState(room, ["interfaceLanguage", "sidebar"]),
    /**
     * Все ответы
     *
     * @returns {Array} - отформатированный набор ответов
     */
    answers() {
      const answers = [];
      for (const group of this.chart.questions_group) {
        answers.push(...group.answers);
      }
      return answers;
    },
    /**
     * Дисплей ли это
     *
     * @returns {boolean} - дисплей ли у пользователя на данный момент
     */
    isScreen() {
      return this.$route.matched.some(match => match.name === "screenRoom");
    },
    /**
     * Вычисляется индекс у всех колонок
     *
     * @returns {object} - индекс колонки
     */
    summaryParameters() {
      return this.answers.reduce(
        (result, item, index) => {
          if (item.countAnswers > result.max) {
            return {
              max: item.countAnswers,
              maxIndex: index,
            };
          }
          if (item.countAnswers === result.max) {
            return {
              max: 0,
              maxIndex: null,
            };
          }
          return {
            max: result.max,
            maxIndex: result.maxIndex,
          };
        },
        { max: 0, maxIndex: 0 },
      );
    },
    averageString() {
      return this.hasAverage
        ? `${this.$t("ResultTests.average")} ${(this.chart.average || 0).toFixed(1)}\n`
        : "";
    },
    hasAverage() {
      return this.chart?.props?.is_average;
    },
    position() {
      return this.$mqMobile || this.isNarrowBar ? BAR_CHART_VIEW_TYPE.horizontal : this.type;
    },
    type() {
      return this.chart?.props?.diagram_type?.for_closed;
    },
    calculatedVerticalBarWidth() {
      return 100 / this.answers.length - 1;
    },
    isDark() {
      return this.interactiveTheme === DARK_THEME;
    },
    mapIndexToColor() {
      return {
        0: this.landingTemplate.first_poll_answer_color,
        1: this.landingTemplate.second_poll_answer_color,
      };
    },
  },
  watch: {
    "sidebar.expanded": async function() {
      await this.$nextTick();
      this.handleResize();
      setTimeout(this.handleResize, this.sideBarDelay);
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      const { offsetWidth } = this.$parent.$el;
      const barWidth = (offsetWidth + this.barIndent) / this.answers.length;
      this.isNarrowBar = barWidth < this.minBarWidth;
    },
    giveColor(index) {
      if (!this.useColors) return null;

      return this.mapIndexToColor[index]
        ? this.mapIndexToColor[index]
        : BAR_COLORS[index % BAR_COLORS.length];
    },
  },
};
</script>

<style lang="less" scoped>
.flip-bars-move {
  transition: all 150ms ease-out;
}

.bar-chart {
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
  color: #313131;
  overflow-x: hidden;

  &.dark {
    color: #ffffff;
  }

  &.vertical-set {
    max-width: 100%;
  }

  &.chart-voting {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .is-voting {
      display: block;
    }
  }

  .mobile-votes-count {
    font-size: 32px;
    color: #6e6e6e;
    width: 100%;
    text-align: center;
    white-space: pre-line;
    margin-bottom: 10px;
  }

  .is-voting {
    display: none;
    font-size: 24px;
    color: #bebebe;
    width: 100%;
  }

  .title {
    width: 100%;
    margin-bottom: 35px;
    font-size: 24px;
    word-break: break-word;
    white-space: pre-wrap;

    b {
      color: #858585;
    }
  }

  .bars-set {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-shrink: 0;
    margin-top: auto;

    &.horizontal {
      align-items: flex-start;
      flex-direction: column;
      margin-top: 0;
    }
  }

  .votes-count {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
    font-size: 32px;
    color: #6e6e6e;

    &__icon {
      margin-right: 10px;
      font-family: simple-line-icons, sans-serif;
      font-style: normal;
      user-select: none;
    }

    &--dark {
      color: #fff;
    }
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    .is-voting {
      font-size: 14px;
    }

    .title {
      font-size: 30px;
      margin-bottom: 17.5px;
    }

    .votes-count {
      font-size: 32px;
      justify-content: center;

      .icon {
        width: 25px;
        height: 29px;
        margin-right: 6px;
      }
    }

    &.chart-voting {
      .mobile-votes-count {
        font-size: 20px;
      }
    }

    .mobile-votes-count {
      font-size: 20px;
    }
  }
  @media (max-width: 600px) {
    max-width: 100%;
    .is-voting {
      font-size: 14px;
      margin-bottom: 18px;
    }

    .title {
      font-size: 28px;
      margin-bottom: 18px;
    }

    .votes-count {
      font-size: 20px;
    }

    &.chart-voting {
      .mobile-votes-count {
        display: block;
        text-align: center;
        margin: 0 auto 14px;
      }
    }
  }

  .bar--increased-mb {
    margin-bottom: 40px;
  }
}

.gradient-groups {
  width: 100%;
}
</style>
