<template>
  <div
    v-if="pollComponent"
    class="poll-score"
    :class="{
      'poll-score--rating': isRating,
      'poll-score--fill-height': fillHeight,
    }"
  >
    <!-- TODO: Правка во время релиза  -->
    <!-- <div class="title">{{ isVoting ? $t("Tests.PollResults") : $t("Tests.PollTestResults") }}</div> -->
    <component
      :is="pollComponent"
      :chart="poll"
      :poll-chain="pollChain"
      :is-voting="isVoting"
      :use-colors="true"
    />
  </div>
</template>

<script>
import { PollType } from "@/constants/polls/poll-types";
import WordCloud from "@/components/common/diagrams/WordCloud";
import BarChart from "@/components/common/diagrams/BarsChart/BarsChart";
import GradientBarChart from "@/components/common/diagrams/BarsChart/GradientBarsChart";
import Rating from "@/components/common/diagrams/Rating/Rating";

const pollComponents = {
  [PollType.WORD_CLOUD]: "WordCloud",
  [PollType.CLOSED]: "BarChart",
  [PollType.SEVERAL]: "BarChart",
  [PollType.ANALOG_SCALE]: "BarChart",
  [PollType.GRADIENT_SCALE]: "GradientBarChart",
  [PollType.RATING]: "Rating",
  [PollType.RELATION_IMAGE]: "BarChart",
  [PollType.CHOOSE_WRONG]: "BarChart",
};
export default {
  name: "PollScoresItem",
  components: {
    WordCloud,
    BarChart,
    Rating,
    GradientBarChart,
  },
  props: {
    poll: {
      type: Object,
      required: true,
    },
    fillHeight: {
      type: Boolean,
      required: true,
    },
    isVoting: {
      type: Boolean,
      default: false,
    },
    pollChain: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isRating() {
      return this.poll.poll_type === "rating";
    },
    pollComponent() {
      return pollComponents[this.poll.poll_type || this.poll.props.poll_type || this.poll.type];
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  width: 100%;
  margin-bottom: 35px;

  @media (max-width: 1280px) {
    margin-bottom: 17.5px;
  }

  font-size: 24px;
  word-break: break-word;
  white-space: pre-wrap;
}

.poll-score {
  flex: 1 0 auto;
  width: 100%;
  padding-bottom: 50px;
  text-align: center;
  -webkit-overflow-scrolling: touch;

  &--rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &--fill-height {
    flex: 1 0 auto;
    min-height: max-content;
  }

  &--fill-height .word-cloud {
    height: 100%;
  }
}
</style>
