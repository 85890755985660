<template>
  <div class="bar" :class="{ dark: dark }">
    <div
      class="result-bar"
      :style="{ width: barData.countAnswers + '%', backgroundColor: color }"
      :class="{ leader: isLeader }"
    ></div>
    <div class="content-wrapper">
      <div class="left-part">
        <div class="index">{{ alphabeticIndex }}</div>
        <div v-if="chart && chart.poll_type != 'choose_wrong'" v-line-clamp="2" class="title">
          {{ barData.variantName }}
        </div>
      </div>
      <div class="percents">{{ roundValue(barData.countAnswers) }}%</div>
    </div>
  </div>
</template>

<script>
import createAplphabeticIndex from "@/utils/createAlphabeticIndex";

export default {
  name: "HorizontalBar",
  props: ["barData", "index", "isLeader", "color", "dark", "chart"],
  data() {
    return {
      alphabeticIndex: "",
    };
  },
  created() {
    this.alphabeticIndex = createAplphabeticIndex(this.index);
  },
  methods: {
    roundValue(floatValue) {
      if (Number.isInteger(floatValue)) {
        return floatValue;
      }
      return floatValue.toFixed(1);
    },
  },
};
</script>

<style scoped lang="less">
.bar {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  height: 77px;
  border-radius: 8px;
  background-color: #dedede;
  margin-bottom: 12px;
  align-items: center;
  font-size: 32px;
  position: relative;
  color: #313131;
  .result-bar {
    height: 77px;
    border-radius: 8px;
    background-color: #bebebe;
    position: absolute;
    left: 0;
    transition: width 0.3s;
    &.leader {
      background-color: #94e118;
    }
  }

  .content-wrapper {
    z-index: 5;
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    padding: 0 12px 0 25px;
  }

  .left-part {
    display: flex;
    flex-grow: 1;
    align-items: center;

    .index {
      padding-right: 25px;
      font-size: 24px;
      color: #939393;
    }

    .title {
      text-align: left;
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 400;
      line-height: inherit;
      word-break: break-word;
      white-space: normal;
    }
  }

  @media (min-width: 601px) {
    height: 45px;
    margin-bottom: 12px;
    align-items: center;
    font-size: 18px;
    position: relative;
    .result-bar {
      height: 45px;
      border-radius: 8px;
      background-color: #bebebe;
      position: absolute;
      left: 0;
      transition: width 0.3s;
      &.leader {
        background-color: #94e118;
      }
    }
    .content-wrapper {
    }
    .percents {
      font-size: 21px;
    }
  }
  @media (max-width: 600px) {
    height: 35px;
    margin-bottom: 10px;
    align-items: center;
    font-size: 16px;
    position: relative;
    .left-part {
      .index {
        font-size: 18px;
      }
    }
    .result-bar {
      height: 35px;
      border-radius: 8px;
      background-color: #bebebe;
      position: absolute;
      left: 0;
      transition: width 0.3s;
      &.leader {
        background-color: #94e118;
      }
    }

    .percents {
      font-size: 16px;
    }
  }
}
</style>
