<script>
export default {
  name: "userCounterMixin",
  props: ["chart", "pollChain"],
  computed: {
    userCountersMessage() {
      return this.getUserCountersMessage();
    },
    userCountersMap() {
      return [
        {
          value:
            this.pollChain.user_count ||
            (this.chart && this.chart.usersCount) ||
            0,
          condition: () => this.hasVoted,
        },
        {
          value: this.pollChain.user_count_day || 0,
          condition: () => this.hasUserCountPerDay,
        },
      ];
    },
    hasVoted() {
      return this.pollChain && this.pollChain.show_total_voters_number;
    },
    hasUserCountPerDay() {
      return this.pollChain && this.pollChain.show_user_count_day;
    },
    hasCounters() {
      return this.hasVoted || this.hasUserCountPerDay;
    },
  },
  methods: {
    getUserCountersMessage() {
      return this.userCountersMap
        .reduce((accum, item) => {
          if (item.condition()) {
            accum.push(item.value);
          }
          return accum;
        }, [])
        .join("/");
    },
  },
};
</script>
