<template>
  <div :class="$style.container">
    <div v-line-clamp="4" :class="$style.title">
      {{ chart.question }}
    </div>
    <div v-if="hasCounters" :class="$style.votesCount">
      <ws-icon light>user-friends</ws-icon>
      <div>{{ userCountersMessage }}</div>
    </div>
    <div :class="$style.chart">
      <div v-if="!wordCloud.length" class="absolute-center">
        {{ $t("Tests.progress") }}
      </div>
      <vue-word-cloud
        v-else
        :words="wordsForCloud"
        :color="setColor"
        :load-font="loadFont"
        :rotation="rotateWord"
        :spacing="0.25"
        font-family="ProximaNova"
      />
    </div>
  </div>
</template>

<script>
import VueWordCloud from "vuewordcloud";
import Chance from "chance";
import FontFaceObserver from "fontfaceobserver";
import { CLOUD_TYPE, WORD_CLOUD_COLORS } from "@/constants/room-tests/room-tests-const";
import userCounterMixin from "@/components/common/diagrams/mixins/userCounterMixin";
import WsIcon from "@/components/base/WsIcon";

let colorIndex = 0;
const MAX_NORMALIZE = 10;
export const ROTATION_UNIT = {
  threeQuarters: 3 / 4,
  oneEighth: 1 / 8,
  sevenEighth: 7 / 8,
  zero: 0,
  one: 1,
};
/**
 * Возвращает нормализованое значение веса для слова зависит от максимального веса слова
 *
 * @param {number|string} weight - Значение веса текущего слова
 * @param {number} maxWeight - Максимальное значение веса из всех слов
 * @returns {number} - Нормализованое значение веса
 */
export function weightNormalize(weight, maxWeight) {
  return Math.ceil(parseInt(weight, 10) / (maxWeight / MAX_NORMALIZE));
}
/**
 * Возвращает наклон слова по типу облака слова
 *
 * @param {object} chance - Инстанс Chance рандомно выбирает из массива
 * @param {string} type - Тип по которому будет возвращен наклон
 * @returns {number} - Наклон для слова
 */
export function getRotationByType(chance, type) {
  const cloudMap = {
    [CLOUD_TYPE.horizontalCloud]: ROTATION_UNIT.one,
    [CLOUD_TYPE.diagonalCloud]: ROTATION_UNIT.sevenEighth,
    [CLOUD_TYPE.crossCloud]: chance.pickone([ROTATION_UNIT.zero, ROTATION_UNIT.threeQuarters]),
    [CLOUD_TYPE.snowflakeCloud]: chance.random(),
    [CLOUD_TYPE.randomCloud]: chance.pickone([
      ROTATION_UNIT.zero,
      ROTATION_UNIT.oneEighth,
      ROTATION_UNIT.threeQuarters,
      ROTATION_UNIT.sevenEighth,
    ]),
  };
  return cloudMap[type] || ROTATION_UNIT.one;
}
/**
 * Страница для отображения списков тестов или опросов
 *
 * @vue-prop {object} chart - данные для построения облака слов
 * @vue-computed {Array} wordCloud - Слова и вес слова
 * @vue-computed {number} maxCloudWeight - Максимальный вес слова
 * @vue-computed {Array} wordsForCloud - Слова с нормализованым весом для плагина облака слова
 * @vue-computed {string} cloudType - Тип облака слова
 */
export default {
  name: "WordCloud",
  components: {
    WsIcon,
    VueWordCloud,
  },
  mixins: [userCounterMixin],
  props: {
    chart: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    wordCloud() {
      return this.chart.props?.word_cloud || [];
    },
    maxCloudWeight() {
      return Math.max(...this.wordCloud.map(item => item.count));
    },
    wordsForCloud() {
      return this.wordCloud.map(({ answer, count = 1 }) => {
        return [answer?.trim(), weightNormalize(count, this.maxCloudWeight)];
      });
    },
    cloudType() {
      return this.chart?.props?.diagram_type?.for_open || CLOUD_TYPE.horizontalCloud;
    },
  },
  methods: {
    rotateWord(word) {
      const chance = new Chance(word[0]);
      return getRotationByType(chance, this.cloudType);
    },
    loadFont(fontFamily, fontStyle, fontWeight, text) {
      return new FontFaceObserver(fontFamily, {
        style: fontStyle,
        weight: fontWeight,
      }).load(text);
    },
    setColor() {
      return WORD_CLOUD_COLORS[colorIndex++ % WORD_CLOUD_COLORS.length];
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  height: 100%;
  width: 100%;
  overflow: hidden;

  @include mq-tablet {
    padding: 0;
  }
}

.title {
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.2em;
  text-align: center;
  word-break: break-word;

  @include mq-tablet {
    font-size: 1.6em;
  }
}

.votesCount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.8em;
  color: #6e6e6e;

  @include mq-tablet {
    font-size: 2.1em;
  }

  & > *:first-child {
    margin-right: 6px;
  }
}

.chart {
  position: relative;
  flex: 1 1 auto;
  min-height: 100px;
  overflow: hidden;
  padding: 15px;
}
</style>
