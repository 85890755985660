/**
 * Константы типов опросов и тестов
 *
 * @namespace PollTypesConsts
 */

/* deprecated */
export const open = "open_answer";
export const relation = "question_correlation";
export const closed = "one_answer";
export const several = "several_answers";
export const relationImage = "relation-images";
export const chooseWrong = "choose_wrong";
export const rating = "rating";
export const wordCloud = "cloud_word";
export const gradientScale = "gradient_scale";
export const analogScale = "analog_scale";

/**
 * Константы типов опросов
 *
 * @memberof PollTypesConsts;
 * @readonly
 * @enum {string}
 */
export const PollType = {
  OPEN: "open_answer",
  RELATION: "question_correlation",
  CLOSED: "one_answer",
  RELATION_IMAGE: "question_correlation",
  CHOOSE_WRONG: "choose_wrong",
  RATING: "rating",
  WORD_CLOUD: "cloud_word",
  SEVERAL: "several_answers",
  ANALOG_SCALE: "analog_scale",
  GRADIENT_SCALE: "gradient_scale",
};

/**
 * Статусы для результатов
 *
 * @memberof PollTypesConsts;
 * @readonly
 * @enum {string}
 */
export const PollStatus = {
  CORRECT: "correct",
  WRONG: "wrong",
  CORRECT_MUTED: "correctMuted",
  NOT_CHECKED: "notChecked",
  PARTIALLY: "partially",
  WRONG_MUTED: "wrongMuted",
  ANSWERED: "answered",
};

/**
 * Объекта для мапинга статуса в имя иконки;
 *
 * @memberof PollTypesConsts;
 * @constant
 * @readonly
 * @type {object}
 */
export const POLL_STATUS_ICONS = {
  [PollStatus.CORRECT]: "check-circle",
  [PollStatus.CORRECT_MUTED]: "check-circle",
  [PollStatus.WRONG]: "times-circle",
  [PollStatus.WRONG_MUTED]: "times-circle",
  [PollStatus.NOT_CHECKED]: "circle",
  [PollStatus.PARTIALLY]: "minus-circle",
  [PollStatus.ANSWERED]: "check-circle",
};
