<template>
  <div class="bar" :style="{ width: defWidth + '%' }" :class="{ dark: dark }">
    <div class="bar-wrapper">
      <div class="percents" :class="{ 'percents--small': smallTextPercent }">
        {{ roundValue(barData.countAnswers) + "%" }}
      </div>
      <div
        class="result-bar"
        :style="{ height: resultHeight, backgroundColor: color }"
        :class="{ leader: isLeader }"
      ></div>
    </div>
    <div class="title">{{ barData.variantName }}</div>
  </div>
</template>

<script>
const BAR_HEIGHT_MULTIPLICATOR = 3;
export default {
  name: "VerticalBar",
  props: ["barData", "index", "isLeader", "color", "def-width", "dark", "smallTextPercent"],
  data() {
    return {};
  },
  computed: {
    resultHeight() {
      return `${(this.barData.countAnswers && this.barData.countAnswers > 0
        ? this.barData.countAnswers
        : 1) * BAR_HEIGHT_MULTIPLICATOR}px`;
    },
  },
  methods: {
    roundValue(floatValue) {
      if (Number.isInteger(floatValue)) {
        return floatValue;
      }
      return floatValue.toFixed(1);
    },
  },
};
</script>

<style scoped lang="less">
.bar {
  display: flex;
  flex-direction: column;
  min-width: 30px;
  max-width: 240px;
  margin-right: 20px;
  &:last-child {
    margin: 0;
  }
  &.dark {
    color: #ffffff;
  }
  .bar-wrapper {
    max-width: 120px;
    width: 100%;
    margin: 0 auto;
    .percents {
      font-size: 32px;
      margin-bottom: 8px;
      text-align: center;

      &--small {
        font-size: 24px;
      }
    }
    .result-bar {
      border-radius: 8px;
      background-color: #bebebe;
      &.leader {
        background-color: #94e118;
      }
    }
  }
  .title {
    margin-top: 40px;
    padding: 0 5px;
    text-align: center;
    font-size: 18px;
    height: 67px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  @media (max-width: 1100px) {
    .title {
      font-size: 16px;
      height: 60px;
    }
  }
}
</style>
