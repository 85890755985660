<template>
  <div
    class="bar-chart"
    :class="{
      'chart-voting': isVoting,
      'vertical-set': position === 'vertical',
      dark: isDark,
    }"
  >
    <p class="title">
      <!-- TODO: Правка во время релиза  -->
      <!-- {{ $t("SpeakerQuestions.answerModal.question") }} -->
      {{ chart.question || chart.name }}
    </p>
    <div v-if="hasAverage" class="mobile-votes-count">{{ averageString }}</div>
    <div v-if="hasCounters" class="votes-count" :class="{ 'votes-count--dark': isDark }">
      <i class="votes-count__icon"></i>
      <div class="votes-count__counter">{{ userCountersMessage }}</div>
    </div>
    <transition-group v-if="position === 'vertical'" tag="div" class="bars-set" name="flip-bars">
      <vertical-bar
        v-for="(bar, index) in answers"
        :key="bar.variantId"
        :bar-data="bar"
        :index="index"
        :dark="isDark"
        :def-width="calculatedVerticalBarWidth"
        :color="giveColor(index)"
        :is-leader="index === summaryParameters.maxIndex"
      />
    </transition-group>
    <transition-group v-else tag="div" class="bars-set horizontal" name="flip-bars">
      <div
        v-for="(bar, index) in answers"
        :key="bar.variantId"
        :class="{ 'wrong-image': chart.poll_type == 'choose_wrong' }"
      >
        <div v-if="chart.poll_type == 'choose_wrong'">
          <img height="100px" :src="bar.variantName" alt="" />
        </div>
        <horizontal-bar
          v-if="!chart.props.is_rating"
          :class="{
            'bar--increased-mb': answers.length === 2 && index === 0 && isScreen,
          }"
          :bar-data="bar"
          :index="index"
          :dark="isDark"
          :color="giveColor(index)"
          :chart="chart"
          :is-leader="index === summaryParameters.maxIndex"
        />
        <div v-else class="rating-result">
          <rating-slider
            class="result-slider"
            custom-width="50%"
            :max="+chart.props.analog_scale_step"
            :value="bar.averageVariantAnswers.toFixed(1) || 0"
            :disabled="true"
          ></rating-slider>
          <p>
            {{ `${$t("testNGames.averageRating")}:  ${+bar.averageVariantAnswers.toFixed(1)}` }}
          </p>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BAR_CHART_VIEW_TYPE, BAR_COLORS } from "@/constants/room-tests/room-tests-const";
import { DARK_THEME } from "@/constants/themes/themes-const";
import { landing, room } from "@/store/modules/store.namespaces";
import userCounterMixin from "@/components/common/diagrams/mixins/userCounterMixin";
import VerticalBar from "./components/VerticalBar";
import HorizontalBar from "./components/HorizontalBar";
import RatingSlider from "@/components/common/elements/RatingSlider";

const BAR_INDENT = 20;
const MIN_BAR_WIDTH = 85;
const SIDE_BAR_DELAY = 300;

export default {
  name: "BarsChart",
  components: {
    HorizontalBar,
    VerticalBar,
    RatingSlider,
  },
  mixins: [userCounterMixin],
  props: {
    chart: {
      type: Object,
      default: () => ({}),
    },
    isVoting: {
      type: Boolean,
      default: false,
    },
    useColors: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isNarrowBar: false,
    };
  },
  computed: {
    ...mapState(["interactiveTheme"]),
    ...mapState(landing, {
      landingTemplate: "template",
    }),
    ...mapState(room, ["interfaceLanguage", "sidebar"]),
    answers() {
      return this.chart.answers;
    },
    isScreen() {
      return this.$route.matched.some(match => match.name === "screenRoom");
    },
    summaryParameters() {
      return this.answers.reduce(
        (result, item, index) => {
          if (item.countAnswers > result.max) {
            return {
              max: item.countAnswers,
              maxIndex: index,
            };
          }
          if (item.countAnswers === result.max) {
            return {
              max: 0,
              maxIndex: null,
            };
          }
          return {
            max: result.max,
            maxIndex: result.maxIndex,
          };
        },
        { max: 0, maxIndex: null },
      );
    },
    averageString() {
      if (this.hasAverage) {
        const average = this.chart.average || 0;
        return `${this.$t("ResultTests.average")} ${average.toFixed(1)}\n`;
      }
      return "";
    },
    hasAverage() {
      return this.chart && this.chart.props && this.chart.props.is_average;
    },
    position() {
      if (this.$mqMobile || this.isNarrowBar) {
        return BAR_CHART_VIEW_TYPE.horizontal;
      }
      return this.type;
    },
    type() {
      return (
        this.chart.props &&
        this.chart.props.diagram_type &&
        this.chart.props.diagram_type.for_closed
      );
    },
    calculatedVerticalBarWidth() {
      return 100 / this.answers.length - 1;
    },
    isDark() {
      return this.interactiveTheme === DARK_THEME;
    },
    mapIndexToColor() {
      return {
        0: this.landingTemplate.first_poll_answer_color,
        1: this.landingTemplate.second_poll_answer_color,
      };
    },
  },
  watch: {
    "sidebar.expanded": async function() {
      await this.$nextTick();
      this.handleResize();
      setTimeout(this.handleResize, SIDE_BAR_DELAY);
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      const { offsetWidth } = this.$parent.$el;
      const barWidth = (offsetWidth + BAR_INDENT) / this.answers.length;
      this.isNarrowBar = barWidth < MIN_BAR_WIDTH;
    },
    giveColor(index) {
      if (!this.useColors) return null;

      return this.mapIndexToColor[index]
        ? this.mapIndexToColor[index]
        : BAR_COLORS[index % BAR_COLORS.length];
    },
  },
};
</script>

<style lang="less" scoped>
.flip-bars-move {
  transition: all 150ms ease-out;
}

.bar-chart {
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
  color: #313131;

  &.dark {
    color: #ffffff;
  }
  &.vertical-set {
    max-width: 100%;
  }
  &.chart-voting {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .is-voting {
      display: block;
    }
  }
  .mobile-votes-count {
    font-size: 32px;
    color: #6e6e6e;
    width: 100%;
    text-align: center;
    white-space: pre-line;
    margin-bottom: 10px;
  }
  .is-voting {
    display: none;
    font-size: 24px;
    color: #bebebe;
    width: 100%;
  }
  .title {
    width: 100%;
    margin-bottom: 35px;
    color: #313131;

    @media (max-width: 1280px) {
      margin-bottom: 17.5px;
    }

    font-size: 24px;
    word-break: break-word;
    white-space: pre-wrap;
  }
  .bars-set {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-shrink: 0;
    margin-top: auto;
    &.horizontal {
      align-items: flex-start;
      flex-direction: column;
      margin-top: 0;
      div {
        width: 100%;
      }
    }

    .wrong-image {
      grid-template-columns: 55px calc(100% - 55px);
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
      }
    }
  }
  .votes-count {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
    font-size: 32px;
    color: #6e6e6e;
    &__icon {
      margin-right: 10px;
      font-family: simple-line-icons, sans-serif;
      font-style: normal;
      user-select: none;
    }
    &--dark {
      color: #fff;
    }
  }
  @media (max-width: 1024px) {
    max-width: 100%;
    .is-voting {
      font-size: 14px;
    }
    .title {
      font-size: 36px;
      margin-bottom: 35px;
    }
    .votes-count {
      font-size: 32px;
      justify-content: center;
      .icon {
        width: 25px;
        height: 29px;
        margin-right: 6px;
      }
    }
    &.chart-voting {
      .mobile-votes-count {
        font-size: 20px;
      }
    }
    .mobile-votes-count {
      font-size: 20px;
    }
  }
  @media (max-width: 600px) {
    max-width: 100%;
    .is-voting {
      font-size: 14px;
      margin-bottom: 18px;
    }
    .title {
      font-size: 28px;
      margin-bottom: 18px;
    }
    .votes-count {
      font-size: 20px;
    }
    &.chart-voting {
      .mobile-votes-count {
        display: block;
        text-align: center;
        margin: 0 auto 14px;
      }
    }
  }

  .bar--increased-mb {
    margin-bottom: 40px;
  }
}
</style>
