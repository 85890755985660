<template>
  <div class="rating" :class="{ 'rating--test': !isVoting }">
    <div class="title">
      {{ title }}
    </div>
    <rating-slider
      class="result-slider"
      :value="chart.average || 0"
      :max="chart.variants.length"
      :disabled="true"
    ></rating-slider>
    <div class="result">
      <span class="result__row">
        {{ $t("testNGames.overallRating") }}
        <span class="result__value">
          {{ chart.average || 0 }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import RatingSlider from "@/components/common/elements/RatingSlider";

export default {
  name: "Rating",
  components: {
    RatingSlider,
  },
  props: ["chart", "isVoting", "useColors"],
  computed: {
    title() {
      return this.chart.question;
    },
    userAnswer() {
      return (
        (this.chart &&
          this.chart.your_answer &&
          this.chart.your_answer.length &&
          this.chart.your_answer[0]) ||
        0
      );
    },
  },
};
</script>

<style scoped lang="less">
.rating--test {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.title {
  margin-bottom: 90px;
  font-size: 24px;
  color: #000000;
  text-align: center;
  letter-spacing: normal;
  word-break: break-word;
  white-space: normal;
}

.result-slider {
  margin: 0 auto 25px;
}

.result {
  font-size: 14px;
  color: #5a5a5a;
  text-align: center;

  &__row {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__value {
    font-weight: bold;
  }
}
</style>
